import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import iconTick from '../../assets/images/svg/tick.svg';
import imgBlockR from '../../assets/images/about-us/Photo 1.png';
import iconTrinity from '../../assets/images/about-us/photo 2.png';

import imgBenefit1 from '../../assets/images/about-us/A rewarding job .svg';
import imgBenefit2 from '../../assets/images/about-us/Comfortable accommodation.svg';
import imgBenefit3 from '../../assets/images/about-us/A network of friends .svg';
import imgBenefit4 from '../../assets/images/about-us/Spanish Bureaucracy .svg';
import imgDots from '../../assets/images/home-page/Dots.png';
// import imgReview from '../../assets/images/about-us/review 1.jpg';

import imgPhoto from '../../assets/images/about-us/Photo 3.png';

//import '../assets/styles/pages/about-us.scss';
import '../../assets/styles/pages/about-us.scss';

const AboutUs = () => {
  return (
    <Layout title={{ id: 'about.seo.title' }} description={{ id: 'about.seo.description' }}>
      <div className="about-us-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="about.sec1.title" />
            </h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-md-6">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="about.sec2.title" />
                  </h2>
                  <p className="c-text-18 block__subtitle">
                    <FormattedMessage id="about.sec2.description" />
                  </p>
                  <p className="c-text-16 block__description">
                    <FormattedMessage id="about.sec2.text1" />
                    <br />
                    <br />
                    <FormattedMessage id="about.sec2.text2" />
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="Tefl Iberia" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={iconTrinity} alt="Trinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="wrapper container">
            <div className="block_l">
              <h3 className="c-title-34">
                <FormattedMessage id="about.sec3.title" />
              </h3>
              <p className="c-text-18">
                <FormattedMessage id="about.sec3.description" />
              </p>
            </div>
            <div className="block_r">
              <div className="benefit">
                <img src={imgBenefit1} alt="" />
                <div className="benefit__text">
                  <h4 className="c-title-18">
                    <FormattedMessage id="about.sec3.ben1.title" />
                  </h4>
                  <p className="c-text-14">
                    <FormattedMessage id="about.sec3.ben1.text" />
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit2} alt="" />
                <div className="benefit__text">
                  <h4 className="c-title-18">
                    <FormattedMessage id="about.sec3.ben2.title" />
                  </h4>
                  <p className="c-text-14">
                    <FormattedMessage id="about.sec3.ben2.text" />
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit3} alt="" />
                <div className="benefit__text">
                  <h4 className="c-title-18">
                    <FormattedMessage id="about.sec3.ben3.title" />
                  </h4>
                  <p className="c-text-14">
                    <FormattedMessage id="about.sec3.ben3.text" />
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit4} alt="" />
                <div className="benefit__text">
                  <h4 className="c-title-18">
                    <FormattedMessage id="about.sec3.ben4.title" />
                  </h4>
                  <p className="c-text-14">
                    <FormattedMessage id="about.sec3.ben4.text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="video container">
            <div className="row">
              <div className="col-md-5 col-12">
                <div className="block_l">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/n80CtVC9PzU"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-md-7 col-12">
                <div className="block_r">
                  <p className="block_r__par c-text-16">
                    <FormattedMessage
                      id="about.sec3.text1"
                      values={{
                        accentedLink: (chunk) => (
                          <a
                            className="capitalized-link"
                            target="_blank"
                            href="https://www.teflcoursereview.com/school/tefl-iberia/"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="about.sec3.text2" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="about.sec4.title" />
                  </h2>
                  <p className="block_l__description c-text-18">
                    <FormattedMessage id="about.sec4.description" />
                  </p>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben3" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben4" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben5" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="about.sec4.ben6" />
                      </p>
                    </div>
                  </div>

                  {/* <p className="block_l__terms">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Languages+4+Life/@41.394561,2.1654172,3a,75y,126.81h,90t/data=!3m7!1e1!3m5!1sqkMPi3upO8QAAAQIt2LQTQ!2e0!3e2!7i5660!8i2830!4m5!3m4!1s0x0:0x7bb78801f01bb2b6!8m2!3d41.3947217!4d2.1654974?hl=es"
                      className="red-link"
                    >
                      Check the school out for yourself with our virtual tour!
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgPhoto} alt="imgPhoto" className="block_r__img" />
                  <img src={imgDots} alt="imgDots" className="dots" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fifth-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="about.sec5.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="about.sec5.btn" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
